// 常用联系人集合
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList'
// 删除常用旅客信息
import consumer_frequent_contacts_del from '@/lib/data-service/haolv-default/consumer_frequent_contacts_del'
//常旅组件
import CommonlyUsedPassenger from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/commonly-used-passenger/1.0.1/index.vue';

export default {
    data() {
        return {

        };
    },
    computed: {},
    components: {
        CommonlyUsedPassenger
    },
    methods: {

    },
    created() {

    },
    activated() {

    },
    mounted() {
        this.$refs.aCommonlyUsedPassenger.init({type: '000'});
    },
    watch: {},
    filters: {},
};
